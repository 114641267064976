<template>
  <div class="campaigns">
    <div class="campaign-list">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../../assets/icons/adone.svg" />
            <h2>{{ $t("campaigns.title") }}</h2>
            <h3>{{ $t("campaigns.info") }}</h3>
          </div>
          <div class="header-right">
            <p>
              <router-link to="/campaigns/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("campaigns.add") }}</span>
              </router-link>
            </p>
          </div>
        </div>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="campaigns.length == 0">
            <div class="well">
              <h3>{{ $t("campaigns.empty") }}</h3>
              <p>
                <router-link to="/campaigns/new" class="button button-ci button-round button-small">
                  <img src="../../assets/icons/plus.svg" />
                  <span>{{ $t("campaigns.addButton") }}</span>
                </router-link>
              </p>
            </div>
          </div>

          <div v-else>
            <div class="row row-gutter-20">
              <div v-for="campaign in campaigns" v-bind:key="campaign.uuid" class="col-6">
                <div class="campaign-wrap">
                  <router-link :to="'/campaigns/' + campaign.uuid">
                    <div class="campaign-img">
                      <div v-if="campaign.advertisment">
                        <div v-if="campaign.advertisment.thumbnail">
                          <img :src="campaign.advertisment.thumbnail" />
                        </div>
                        <div v-else>
                          <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                        </div>
                      </div>
                      <div v-else>
                        <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                      </div>
                    </div>
                  </router-link>

                  <div class="campaign-title">
                    <h3>
                      <span v-if="campaign.status == 'draft'" class="badge">{{ $t("status.draft") }}</span>
                      <span v-if="campaign.status == 'creating'" class="badge">{{ $t("status.creating") }}</span>
                      <span v-if="campaign.status == 'created'" class="badge">{{ $t("status.created") }}</span>
                      <span v-if="campaign.status == 'pending'" class="badge">{{ $t("status.pending") }}</span>
                      <span v-if="campaign.status == 'running'" class="badge">{{ $t("status.running") }}</span>
                      <span v-if="campaign.status == 'stopped'" class="badge">{{ $t("status.stopped") }}</span>
                      <span v-if="campaign.status == 'paused'" class="badge">{{ $t("status.paused") }}</span>
                      <span v-if="campaign.status == 'blocked'" class="badge">{{ $t("status.blocked") }}</span>
                      <span v-if="campaign.status == 'deleted'" class="badge">{{ $t("status.deleted") }}</span>
                      <router-link :to="'/campaigns/' + campaign.uuid">
                        <span class="name">{{ campaign.name }}</span>
                      </router-link>

                      <span v-if="campaign.status == 'created'" @click="delete_campaign(campaign.uuid)" class="campaign-delete material-icons">delete</span>
                    </h3>
                  </div>
                  <div class="campaign-meta">
                    <div class="row">
                      <div class="col-8">
                        <img src="../../assets/icons/campaign-eur.svg" />
                        <span>{{ campaign.budget_formatted }}</span>
                      </div>
                      <div class="col-8">
                        <img src="../../assets/icons/campaign-impr.svg" />
                        <span>{{ campaign.impressions }}</span>
                      </div>
                      <div class="col-8">
                        <img src="../../assets/icons/campaign-contact.svg" />
                        <span>{{ campaign.contacts }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-gutter-20" style="margin-top: 20px">
              <div class="col-12">
                <a v-if="pagination.links.prev" @click="prev_page" class="button button-ci button-100 button-round button-small">{{ $t("global.prevPage") }}</a>
                <a v-else>-</a>
              </div>
              <div class="col-12" style="text-align: right">
                <a v-if="pagination.links.next" @click="next_page" class="button button-ci button-100 button-round button-small">{{ $t("global.nextPage") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "campaigns",
  data() {
    return {
      errors: [],
      loading: true,
      campaigns: [],
      pagination: {},
    };
  },
  methods: {
    fetchCampaigns() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.campaigns = response.data.campaigns;
          this.pagination = response.data.meta.pagination;
        });
    },
    get_campaigns(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.campaigns = response.data.campaigns;
          this.pagination = response.data.meta.pagination;
          this.loading = false;
        });
    },
    delete_campaign(uuid) {
      axios
        .delete(process.env.VUE_APP_BASE_API + "/campaigns/" + uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.deletedSuccess"),
          });

          console.log(response);
          this.fetchCampaigns();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    next_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.next.split("=")[1]);
    },
    prev_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.prev.split("=")[1]);
    },
  },
  mounted() {
    this.fetchCampaigns();
    this.get_campaigns(1);
  },
};
</script>

<style>
.campaign-list {
  padding: 40px 0;
}

.campaign-list .campaign-wrap {
  background: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 5px;
  display: block;
  color: #444;
  margin-bottom: 20px;
}

.campaign-list .campaign-wrap .campaign-img {
  width: 100%;
}

.campaign-list .campaign-wrap .campaign-img img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: lightgray;
}

.campaign-list .campaign-wrap .campaign-title {
  padding: 10px 14px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

.campaign-list .campaign-wrap .campaign-title h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.campaign-list .campaign-wrap .campaign-title h3 .name {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign-list .campaign-wrap .campaign-title h3 .badge {
  font-size: 12px;
  margin-right: 10px;
  font-weight: 400;
  background-color: rgba(24, 144, 255, 0.1);
  color: #1890ff;
  border: 1px solid rgba(24, 144, 255, 0.2);
  padding: 4px 8px;
  border-radius: 3px;
  width: initial;
}

.campaign-list .campaign-wrap .campaign-meta {
  padding: 10px 14px;
  text-align: center;
}

.campaign-list .campaign-wrap .campaign-meta .col-8 span {
  display: inline-block;
  vertical-align: middle;
}

.campaign-list .campaign-wrap .campaign-meta .col-8 img {
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  margin-right: 5px;
}

.campaign-title {
  position: relative;
}

.campaign-title .name {
  color: black;
}

.campaign-delete {
  position: absolute;
  right: 10px;
  top: 10px;
}

.campaign-delete:hover {
  cursor: pointer;
}
</style>
